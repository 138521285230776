<template>
  <div class="scene-section">
    <Header :showback="true">
      <template slot="title">每日答题</template>
    </Header>
    <div>
      <van-swipe
              ref="swipe"
              class="swpipe"
              :loop="false"
              indicator-color="white"
      >
        <van-swipe-item v-for="(record, index) in recordList" :key="index">
          <div class="van-doc-demo-block">
            <h2 class="van-doc-demo-block__title">
              <span class="question_title"
              >{{ index + 1 }}.{{ record.name }}</span
              >
              <div>请从下列选出正确答案</div>
            </h2>
            <div>
              <div class="question_tab">
                <img
                        class="question_tab_img"
                        src="../../assets/images/mine/3.png"
                        alt=""
                        @click="changeTi(index, 1, $event)"
                />
                <img
                        class="question_tab_img"
                        src="../../assets/images/mine/2.png"
                        alt=""
                        @click="changeTi(index, 2, $event)"
                />
                <img
                        class="question_tab_img"
                        src="../../assets/images/mine/1.png"
                        alt=""
                        @click="changeTi(index, 3, $event)"
                />
                <img
                        class="question_tab_img"
                        src="../../assets/images/mine/4.png"
                        alt=""
                        @click="changeTi(index, 4, $event)"
                />
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" @load="onLoad">
          <div class="cell" v-for="(record, index) in recordList" :key="index">
            <div class="van-doc-demo-block">
              <h2 class="van-doc-demo-block__title">
                <span class="question_title"
                  >{{ index + 1 }}.{{ record.name }}</span
                >
                <div>请从下列选出正确答案</div>
              </h2>
              <div>
                <div class="question_tab">

                  <img
                    class="question_tab_img"
                    src="../../assets/images/mine/3.png"
                    alt=""
                    @click="changeTi(index, 1, $event)"
                  />
                  <img
                    class="question_tab_img"
                    src="../../assets/images/mine/2.png"
                    alt=""
                    @click="changeTi(index, 2, $event)"
                  />
                  <img
                    class="question_tab_img"
                    src="../../assets/images/mine/1.png"
                    alt=""
                    @click="changeTi(index, 3, $event)"
                  />
                  <img
                    class="question_tab_img"
                    src="../../assets/images/mine/4.png"
                    alt=""
                    @click="changeTi(index, 4, $event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh> -->
      <div class="submit">
        <div class="submitbtn" @click="clickSubmit">提交</div>
      </div>
    </div>
    <van-popup
            v-model="showResult"
            closeable
            size="medium"
            type="primary"
            @close="closeShowResult"
            style="width: 90%; margin: 5% 0; padding: 5%; border-radius: 8px"
    >
      <div style="font-weight: bold; text-align: center; font-size: 25px">
        本次得分：{{ score }}
      </div>
      <div class="cell" v-for="(record, index) in recordList" :key="index">
        <div class="van-doc-demo-block">
          <h2 class="van-doc-demo-block__title" style="color: #000">
            {{ index + 1 }}.
            <span class="question_title">{{ record.name }}</span>
            属于哪种类型垃圾?
          </h2>
          <div v-for="(item, i) in answerList" :key="i">
            <template v-if="success[index] == checked[index]">
              <van-cell v-if="success[index] == item.cate_id">
                <van-tag type="success">回答正确：{{ item.name }}</van-tag>
              </van-cell>
            </template>
            <template v-else>
              <van-cell v-if="success[index] == item.cate_id">
                <van-tag type="success">正确答案：{{ item.name }}</van-tag>
              </van-cell>
              <van-cell v-if="checked[index] == item.cate_id">
                <van-tag type="danger">回答错误：{{ item.name }}</van-tag>
              </van-cell>
            </template>
          </div>
        </div>
      </div>
    </van-popup>
    <div class="systemcon">
      <van-popup v-model="showIntroduce" :close-on-click-overlay="false">
        <div class="introduce">
          <div class="content">
            <div class="title">
              Environmental Ecosystem<br/>
              环保生态系统</div>
            <div class="introducetxt">
              The environmental protection ecosystem is a habit-forming application dedicated to the classification of waste in the environmental protection industry. In the process, it will promote the continuous improvement of the global ecological environment, and people will enjoy a more superior living environment!
              <br />环保生态系统是一款致力于环保事业垃圾分类的习惯养成类应用，旨在唤醒人类增强环保意识，鼓励更多人学习垃圾分类知识和宣传绿色环保理念，随着更多人参与到环保生态中，将推动全球生态环境不断改善，人们将享受更加优越的生活环境！
            </div>
            <van-count-down
                    ref="countDown"
                    :time="time"
                    format="ss"
                    @finish="finish"
            />
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
    import Header from "../../components/header.vue";
    import { Toast } from "vant";
    import { questionList, submitAnswer } from "@/request/api";
    import $ from "jquery";
    export default {
        name: "",
        components: {
            Header,
        },
        data() {
            return {
                time: 10 * 1000,
                showIntroduce: true,
                refreshing: false,
                loading: false,
                finished: false,
                recordList: [],
                checked: [],
                address: "",
                success: [],
                showResult: false,
                score: 0,
                answerList: [
                    { cate_id: 1, name: "厨余垃圾", name_en: "Kitchen waste" },
                    { cate_id: 2, name: "可回收垃圾", name_en: "recyclable trash" },
                    { cate_id: 3, name: "有害垃圾", name_en: "hazardous waste" },
                    { cate_id: 4, name: "其他垃圾", name_en: "other garbage" },
                ],
            };
        },
        activated() {
            this.showIntroduce = true;
            this.time = 6 * 1000;
            this.$refs.countDown.reset();
            this.$refs.countDown.start();
            this.address = sessionStorage.getItem("address");
            this.checked = [];
            this.success = [];
            if (this.address && sessionStorage.getItem(this.address)) {
                this.onRefresh();
            }
        },
        methods: {
            finish() {
                this.showIntroduce = false;
            },
            closeShowResult() {
                this.showResult = false;
            },
            clickSubmit() {
                let _this = this;
                if (!this.address || !sessionStorage.getItem(this.address)) {
                    Toast("请使用币安智能链链接");
                    return false;
                }
                if (this.checked.length != this.recordList.length) {
                    Toast("请完善答案");
                    return;
                }
                // 计算得分
                let rate = parseInt(100 / parseInt(this.recordList.length));
                let socre = 0;
                let error = 0;
                this.success = [];
                $.each(this.checked, function (index, ele) {
                    if (ele == null) {
                        Toast("您的第" + (index + 1) + "道题还未完成");
                        return;
                    }
                    if (_this.recordList[index].cate_id == ele) {
                        socre += rate;
                    } else {
                        error += 1;
                    }
                    _this.success[index] = _this.recordList[index].cate_id;
                });
                if (error == 0) {
                    socre == 100;
                }
             /*   if (socre<50) {
                    Toast("今日分数太低,无法完成转换器或环保任务");
                    return;
                }*/
                const params = {
                    address: this.address,
                    score: socre,
                };
                submitAnswer(params).then((res) => {
                    if (res.code == 200) {
                        if(socre<=25){
                            Toast("本次得分:" + socre+',继续努力');
                        }else{
                            Toast("本次得分:" + socre+',已通过');
                        }

                        // Toast("参与成功,本次得分:" + socre);
                        setTimeout(function () {
                            _this.showResult = true;
                            _this.score = socre;
                            // Toast('正确答案');
                            // _this.checked = success;
                        }, 3000);
                    } else {
                        Toast(res.msg);
                    }
                });
                console.log(socre);
                console.log(error);
                console.log(_this.success);
            },
            changeTi(index, cate_id, event) {
                this.$refs.swipe.next();
                this.checked[index] = cate_id;
                let toggle = event.currentTarget;
                $(toggle).siblings().removeClass("question_tab_img_active");
                $(toggle)
                    .parent()
                    .siblings()
                    .children()
                    .removeClass("question_tab_img_active");
                $(toggle).addClass("question_tab_img_active");
                console.log(this.checked);
                console.log(cate_id);
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.currentPage = 1;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.refreshing = true;
                this.onLoad();
            },
            onLoad() {
                this.getDataList();
            },
            getDataList() {
                const params = {
                    address: this.address,
                    page: this.currentPage,
                    limit: "20",
                };
                questionList(params).then((res) => {
                    if (this.refreshing) {
                        this.recordList = [];
                        this.refreshing = false;
                    }
                    this.loading = false;
                    this.recordList = res.data;
                    this.finished = true;
                });
            },
        },
    };
</script>
<style scoped lang="scss">
  ::v-deep .van-count-down {
    font-size: 30px;
    color: white;
    text-align: center;
  }
  .systemcon {
    ::v-deep .van-popup {
      border-radius: 6px;
    }
  }
  .introduce {
    width: 700px;
    height: 100%;

    background-color: #078538;
    .content {
      padding: 24px;
      color: white;
      font-weight: bold;
      .title {
        text-align: center;
        font-size: 38px;
      }
      .introducetxt {
        margin-top: 15px;
        font-size: 30px;
        line-height: 50px;
      }
    }
  }
  .swpipe {
    height: 900px;
    background: transparent;
  }
  .scene-section {
    background: #0c1913;
    min-height: 100vh;
  }
  .demo-radio .van-doc-demo-block__title {
    margin-top: -8px;
  }
  .question_title {
    font-weight: bold;
    color: #27cc7f;
  }
  .question_tab {
    display: flex;
    flex-wrap: wrap;
    padding: 2% 5%;
    justify-content: space-around;
  }
  .question_tab_img {
    width: 35%;
    margin-bottom: 5%;
  }
  .question_tab_img_active {
    border: 8px solid #fff;
  }
  .van-doc-demo-block__title {
    margin: 0;
    padding: 16px 16px 16px;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .submit {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    .submitbtn {
      width: 320px;
      height: 88px;
      border-radius: 16px;
      opacity: 1;
      background: rgba(39, 204, 127, 1);
      color: rgba(32, 40, 35, 1);
      font-size: 36px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 88px;
      margin-bottom: 50px;
    }
  }
</style>
